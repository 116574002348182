$black-87-opacity: rgba(black, 0.87);
$white-87-opacity: rgba(white, 1);

$mat-loi: (
	100: #b1c7dd,
	500: #025aa5,
	700: #303f9f,
	contrast: (
		100: $black-87-opacity,
		500: $white-87-opacity,
		700: $white-87-opacity,
	),
);

$blue: #025aa5; // 3f51b5
$black: #000;
$white: #fff;

$loi-gray: #d7dde4;
$loi-light-gray: #f0f3f6;
$loi-blue: #97b9d6;
$loi-red: #d69797;
$loi-green: #97d6bf;

$enable-rounded: false;

// Body
$body-bg: $loi-light-gray;

// Grid

$grid-breakpoints: (
	xs: 0,
	sm: 600px,
	md: 768px,
	lg: 992px,
	xl: 1400px,
);
$grid-columns: 16;

// Navbar

$navbar-padding-x: 1.5rem;
$navbar-padding-y: 0.25rem;

// Card

$card-border-color: rgba($black, 0.09);
$card-spacer-y: 1rem;
$card-cap-bg: $white;

$card-header-spacer-y: 0.5rem;
$card-body-table-spacer-x: 0rem;
$card-body-table-spacer-y: 0rem;

$table-border-color: rgba($black, 0.09);
