@use '@angular/material' as mat;
@import './bs-override';
@import 'bootstrap/scss/bootstrap';

$fa-font-path: '~font-awesome/fonts';
@import 'font-awesome/scss/font-awesome';

////////////////////////////////////////////////////////////////////////////////////////////////////
// Angular Material
////////////////////////////////////////////////////////////////////////////////////////////////////
@import 'material-design-icons/iconfont/material-icons.css';

@include mat.core();

// using deeppurple-amber color combo: https://github.com/angular/material2/blob/master/src/lib/core/theming/prebuilt/deeppurple-amber.scss
$primary: mat.define-palette($mat-loi);
//$accent:  mat-palette($mat-cyan, A200, A100, A400);
$accent: mat.define-palette(mat.$cyan-palette);

$theme: mat.define-light-theme($primary, $accent);

// Include all theme styles for the components.
@include mat.all-component-themes($theme);

mat-datepicker-toggle .mat-icon-button {
	width: 26px !important;
	height: 20px !important;
	line-height: 20px !important;
}

$bg-color: mat.get-color-from-palette($primary, 0.12);

////////////////////////////////////////////////////////////////////////////////////////////////////
// ag-Grid
////////////////////////////////////////////////////////////////////////////////////////////////////

// set the colors to the ones from the material theme
$primary-color: mat.get-color-from-palette($primary);
$accent-color: mat.get-color-from-palette($accent);

$ag-icons-path: '~ag-grid/src/styles/icons/';
@import 'ag-grid/src/styles/ag-grid';

// adjust the icons path
$ag-mat-icons-path: '~ag-grid/src/styles/material-icons/';

$grid-size: 5px;
// AG-GRID Version 14.2 ERROR: ag-virtual-list-item top (inline style) is fixed -> cant be changend

@import 'ag-grid/src/styles/ag-theme-material';

.ag-theme-material {
	.ag-filter {
		input[type='text'] {
			height: 25px !important;
		}
		.ag-filter-value {
			line-height: 25px !important;
		}
	}

	.ag-column-select-panel {
		z-index: 2000; // bug fix: um spalten ein und ausblenden zu können
	}

	.ag-column-name-filter {
		height: 25px !important; // damit man nach spalten filtern kann
	}

	.ag-virtual-list-item {
		line-height: 25px !important;
	}
}

// If I use a position relative for an item which is inside column-count, it does not appear in Chrome
// see also US 9724. Solution:
ag-grid-angular {
	-webkit-column-break-inside: avoid;
	-webkit-backface-visibility: hidden;
}

/*
 * ****** OVERRIDES
 */

/*
 * bs overrides -> definitions without variables
 */

.navbar-brand {
	padding-top: 0.1rem;
}

.navbar {
	padding: 0.25rem 4.5rem 0.25rem 1.5rem;
}

.card-columns-3 {
	.card {
		margin-bottom: $card-columns-margin;
	}

	@include media-breakpoint-down(xl) {
		column-count: 3;
		column-gap: $card-columns-gap;

		.card {
			display: inline-block; // Don't let them vertically span multiple columns
			width: 100%; // Don't let their width change
		}
	}
	@include media-breakpoint-down(lg) {
		column-count: 2;
		column-gap: $card-columns-gap;

		.card {
			display: inline-block; // Don't let them vertically span multiple columns
			width: 100%; // Don't let their width change
		}
	}
	@include media-breakpoint-down(sm) {
		column-count: 1;
		column-gap: $card-columns-gap;

		.card {
			display: inline-block; // Don't let them vertically span multiple columns
			width: 100%; // Don't let their width change
		}
	}
}

.card-columns-2 {
	.card {
		margin-bottom: $card-columns-margin;
	}

	@include media-breakpoint-down(xl) {
		column-count: 2;
		column-gap: $card-columns-gap;

		.card {
			display: inline-block; // Don't let them vertically span multiple columns
			width: 100%; // Don't let their width change
		}
	}
	@include media-breakpoint-down(lg) {
		column-count: 1;
		column-gap: $card-columns-gap;

		.card {
			display: inline-block; // Don't let them vertically span multiple columns
			width: 100%; // Don't let their width change
		}
	}
}

.card-columns-1 {
	.card {
		margin-bottom: $card-columns-margin;
	}

	@include media-breakpoint-down(xl) {
		column-count: 1;
		column-gap: $card-columns-gap;

		.card {
			display: inline-block; // Don't let them vertically span multiple columns
			width: 100%; // Don't let their width change
		}
	}
}

.card-header {
	padding: 0;
}

.card-body-table {
	//padding: $card-body-table-spacer-y $card-body-table-spacer-x $card-spacer-y $card-body-table-spacer-x;
	padding: $card-body-table-spacer-y $card-body-table-spacer-x;
}

.bg-light {
	background-color: $loi-gray !important;
}

.alert {
	padding: 0.5rem 0.5rem !important;
	margin-bottom: 0;
}

.nav-pills .nav-link.active,
.nav-pills .show > .nav-link {
	border-bottom: 2px solid mat.get-color-from-palette($primary);
	color: inherit;
	background-color: inherit;
}

/*
 * chrome overrides
 */

// Removing input background colour for Chrome autocomplete
input:-webkit-autofill {
	box-shadow: 0 0 0 30px white inset;
	-webkit-box-shadow: 0 0 0 30px white inset;
}

/*
 * Notification overrides
 */

.simple-notification {
	padding: 3px 10px !important;
	margin-bottom: 3px !important;
	padding: 3px 10px 6px 10px !important;
	min-height: 0px !important;
}

.simple-notification .icon {
	width: 45px !important;
	height: 45px !important;
}

.simple-notification .sn-content {
	font-size: 13px !important;
	line-height: 15px !important;
}

.simple-notification.info {
	background: $loi-blue !important;
}
.simple-notification.info .sn-progress-loader span {
	background: darken($loi-blue, 20%) !important;
}

.simple-notification.error {
	background: $loi-red !important;
}
.simple-notification.error .sn-progress-loader span {
	background: darken($loi-red, 20%) !important;
}

.simple-notification.success {
	background: $loi-green !important;
}

.simple-notification.success .sn-progress-loader span {
	background: darken($loi-green, 20%) !important;
}

.simple-notification .sn-progress-loader {
	height: 3px !important;
}

/*
 * NEW Styles
 */

.tab-header-pd {
	padding: 0.5rem 1.25rem;
}

.tab-header {
	display: inline-flex;
	margin: 0px;
}

// display arrow up and down for each collapse panel
[data-toggle='collapse'] i:before {
	content: '\f139';
	margin-right: 0.5rem;
}
[data-toggle='collapse'].collapsed i:before {
	content: '\f13a';
	margin-right: 0.5rem;
}

// display gird row differences
.bg-xyz-diff {
	background-color: #d6e5f2;
}

.form-control:focus {
	border: 1px solid $blue;
}

.card-header > .box {
	width: 60px;
	background-color: #f8f9fa;
	float: left;
	text-align: center;
	margin-right: 1rem;
	cursor: pointer;
}

.card-header > .box.active {
	background-color: $loi-gray;
}

.card-header > .tab {
	background-color: #f8f9fa;
	border-left: 1px solid #dae0e5;
	float: right;
	margin: 0;
	text-align: center;
	cursor: pointer;
	padding: 0px 15px;
}

.card-header > .tab:not([disabled]):not(.disabled):active,
.card-header > .tab:not([disabled]):not(.disabled).active,
.card-header > .tab.active {
	background-color: $loi-gray;
}

.card-header > .tab:hover {
	color: #111;
	background-color: #e2e6ea;
	border-color: #dae0e5;
}

.hide {
	display: none !important;
}

.highlight {
	background-color: #d4e6e0 !important;
}

.card-loader {
	height: 2px !important;
	position: absolute !important;
	z-index: 1;
}

.card-loader-container {
	position: relative;
}

.fa:hover {
	color: mat.get-color-from-palette($primary);
}

// material override bs
*:focus {
	outline: none !important;
}

*:active {
	outline: none !important;
}

.full-width {
	width: 100%;
}

.tp-box__front:hover {
	background: $bg-color;
}

.tp-box__front:active {
	color: mat.get-color-from-palette($primary);
}

loi-tile a {
	color: #5d5d5d !important;
}
loi-tile a:hover {
	color: mat.get-color-from-palette($primary) !important;
}
